import React from 'react';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { LocationAnswerType, ProtectorType } from 'utils/api/types';

import EntitySearchField from 'components/EntitySearchField';

import API from 'utils/api';

import CustomDropdownField from '../CustomDropdownField';
import CustomUserDropdownField from '../CustomUserDropdownField';
import { IncidentFormFieldProps, ProtectorTypeLookupProps, ProtectorTypeStatusProps } from '../types';
import CustomDatePicker from '../CustomDatePicker';
import CustomTextInputField from '../CustomTextInputField';
import CustomNumberInputField from '../CustomNumberInputField';
import CustomDateTimePicker from '../CustomDateTimePicker';
import CustomTimePicker from '../CustomTimePicker';
import CustomImage from '../CustomImage';
import CustomStatus from '../CustomStatus';
import CustomLocationTextInput from '../CustomLocationTextInput';

export const IncidentFormField = (
  props: IncidentFormFieldProps & { requiredNotFilled: boolean }
): React.ReactElement => {
  const { type, value, onChange, description, items, originalValue, path, schema, requiredNotFilled } = props;
  if (type === undefined) {
    return <p>Type not supported</p>;
  }

  const commonTextInputProps = {
    value,
    onChange,
    description,
    originalValue,
    path,
    schema,
    requiredNotFilled,
  };

  const enumItems = items || { enum: [] };

  switch (type) {
    case ProtectorType.Image:
      return <CustomImage {...props} />;
    case ProtectorType.Time:
      return <CustomTimePicker {...props} />;
    case ProtectorType.DateTime:
      return <CustomDateTimePicker {...props} />;
    case ProtectorType.Number:
      return <CustomNumberInputField {...props} />;
    case ProtectorType.ShortText:
      return <CustomTextInputField {...commonTextInputProps} />;
    case ProtectorType.LongText:
      return <CustomTextInputField {...commonTextInputProps} multiline />;
    case ProtectorType.SingleChoice: {
      return <CustomDropdownField {...props} items={enumItems} />;
    }
    case ProtectorType.MultiChoice: {
      return <CustomDropdownField {...props} items={enumItems} />;
    }
    case ProtectorType.User:
      return <CustomUserDropdownField {...props} />;
    case ProtectorType.Date:
      return <CustomDatePicker {...props} />;
    case ProtectorType.Status:
      return <CustomStatus {...(props as ProtectorTypeStatusProps)} />;
    case ProtectorType.Location:
      return (
        <CustomLocationTextInput
          onChange={onChange}
          location={value ? (value as LocationAnswerType).address : undefined}
          requiredNotFilled={requiredNotFilled}
        />
      );
    case ProtectorType.Lookup:
      return (
        <EntitySearchField
          field="lookup"
          updateField={(field: string, val: UuidableName | null) => {
            onChange(val ? val.uuid : null);
          }}
          data={value ? (value as UuidableName) : null}
          searchApi={() => API.searchEntity((props as ProtectorTypeLookupProps).lookupType)}
        />
      );
    default:
      return <p>Type not supported</p>;
  }
};

export default IncidentFormField;
