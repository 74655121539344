import React from 'react';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { UuidableName, SearchResponse } from 'vatix-ui/lib/utils/api/types';

import { Box, MenuItem } from '@mui/material';

import styled from 'styled-components';

import { APIRequest } from 'utils/api/types';

import { StyledAutocomplete, StyledTextField } from 'components/Input/styles';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as SearchResponse;
const nullOption = { uuid: 'null', name: '' } as SearchResponse;

const StyledMenuItem = styled(MenuItem)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  width: 100%;
`;

const EntitySearchField: React.FunctionComponent<{
  field: string;
  updateField: (field: string, value: UuidableName | null) => void;
  data: UuidableName | null;
  searchApi: (
    params?: Partial<{
      query: string;
      limit: number;
      offset: number;
    }>
  ) => APIRequest<UuidableName[]>;
}> = ({ field, updateField, data, searchApi }): React.ReactElement => {
  const { results, loading, searchQuery } = useSearch(searchApi, undefined, { limit: 10 });

  const [selectedEntity, setSelectedEntity] = React.useState<SearchResponse | null | undefined>(data);

  const onChangeSearch = async (s: string): Promise<void> => {
    await searchQuery(s);
  };

  return (
    <StyledAutocomplete
      key={`${selectedEntity?.name}_${selectedEntity?.uuid}`}
      id={`${field}-dropdown-field`}
      options={results}
      value={selectedEntity === undefined ? nullOption : selectedEntity}
      renderOption={(props, option: UuidableName) => {
        const { ...restProps } = props;
        return option.uuid === loadingOption.uuid ? (
          <CenteredCircularProgress />
        ) : (
          <Box sx={{ alignItems: 'center' }}>
            <StyledMenuItem
              key={`${option.uuid}`}
              {...restProps}
              value={option.uuid}
              onClick={() => {
                setSelectedEntity({
                  name: option.name,
                  uuid: option.uuid,
                });
                updateField(field, { name: option.name, uuid: option.uuid });
              }}
            >
              {option.name}
            </StyledMenuItem>
          </Box>
        );
      }}
      getOptionLabel={(option: SearchResponse) => option?.name as string}
      getOptionDisabled={(option: SearchResponse) =>
        option.uuid === loadingOption.uuid || option.uuid === selectedEntity?.uuid
      }
      renderInput={(params) => (
        <StyledTextField
          {...params}
          onChange={(e) => {
            onChangeSearch(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
          placeholder="Search"
        />
      )}
      loading={loading}
      fullWidth
      disableClearable={!selectedEntity?.uuid}
      onChange={() => {
        setSelectedEntity(undefined);
        searchQuery('');
      }}
      onInputChange={(_, __, reason) => {
        if (reason === 'clear') {
          updateField(field, null);
        }
      }}
    />
  );
};

export default EntitySearchField;
