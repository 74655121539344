import { TriggerType } from 'utils/api/types';
import { AmberAlertActivityStatus, RedAlertActivityStatus } from 'utils/stores/Activities/constants';

export const INTERCOM_APP_ID = 'iunlg1or';

export const HELP_CENTER_URL = 'https://help.vatix.com/';

export const ADD_MULTIPLE_USERS_HELP_URL =
  'https://help.vatix.com/en/articles/10032363-how-to-add-multiple-users-at-once';

export const FIELD_MANAGER_HELP_URL =
  'https://help.vatix.com/en/articles/9896896-how-to-use-the-field-manager#h_342f91dcda';

export const LAYOUT_EDITOR_HELP_URL = 'https://help.vatix.com/en/articles/9896912-how-to-use-the-layout-editor';

export enum EntityModules {
  Assets = 'assets',
  Sites = 'sites',
  Suppliers = 'suppliers',
  Risks = 'risks',
  Incidents = 'incidents',
}

export enum TaskStatus {
  ToDo = 'todo',
  CantDo = 'cant_do',
  InProgress = 'in_progress',
  Completed = 'completed',
}

export const TaskStatusNames = Object.freeze({
  [TaskStatus.ToDo]: 'To Do',
  [TaskStatus.CantDo]: 'On hold',
  [TaskStatus.InProgress]: 'In Progress',
  [TaskStatus.Completed]: 'Completed',
});

export enum NotificationTarget {
  NEW_TASK_ASSIGNED_TO_ME = 'new_task_assigned_to_me',
  NEW_INCIDENT_MESSAGE = 'incident_comment_mention',
  NEW_INCIDENT_SUBMISSION_MESSAGE = 'incident_submission_comment_mention',
  NEW_INCIDENT_REPORTED = 'incident_has_been_reported',
  NEW_INCIDENT_ASSIGNED_TO_ME = 'new_incident_assigned_to_me',
  NEW_TASK_MESSAGE = 'task_comment_mention',
  NEW_FORM_SUBMITTED = 'new_form_submitted',
}

export enum UserRole {
  User = 'user',
  Manager = 'manager',
  AccountManager = 'account_manager',
  ArcOperator = 'arc_operator',
  Admin = 'admin',
}

export const UserRoleNames: Record<string, string> = Object.freeze({
  manager: 'Manager',
  account_manager: 'Account Owner',
  account_owner: 'Account Owner',
  user: 'User',
  admin: 'Admin',
  arc_operator: 'ARC Operator',
});

export const ProductLicenseName: Record<string, string> = Object.freeze({
  protectorAlarms: 'Lone Working',
  protectorIncidents: 'Incidents',
  workflows: 'Audits',
});

export enum LicenseType {
  ProtectorMonitoringSelf = 'protector_monitoring_self',
  ProtectorMonitoringVatix = 'protector_monitoring_vatix',
}

export const LicenseTypeName = Object.freeze({
  [LicenseType.ProtectorMonitoringSelf]: 'Self Monitoring',
  [LicenseType.ProtectorMonitoringVatix]: 'Vatix 24/7 Monitoring',
});

export enum AlertStatus {
  RedAlert = 'red_alert',
  AmberAlert = 'amber_alert',
  NoAlert = 'no_alert',
}

export enum AssignmentStatus {
  Assigned = 'assigned',
  Unassigned = 'unassigned',
}

export const TriggerTypeName = Object.freeze({
  [TriggerType.Button]: 'SOS Pressed',
  [TriggerType.ExpiredTimer]: 'Expired Timer',
});

export const GOOGLE_MAPS_DEFAULT_POSITION = {
  position: {
    lat: 51.510033,
    lng: -0.108169,
  },
};

export const GOOGLE_MAPS_STYLES: google.maps.MapTypeStyle[] = [
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#545454',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        saturation: -87,
      },
      {
        lightness: -40,
      },
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#f0f0f0',
      },
      {
        saturation: -22,
      },
      {
        lightness: -16,
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        saturation: -52,
      },
      {
        hue: '#00e4ff',
      },
      {
        lightness: -16,
      },
    ],
  },
];

export enum RedAlertStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Completed = 'completed',
}

export enum IncidentsStatus {
  Open = 'open',
  Resolved = 'resolved',
  Archived = 'archived',
}

export enum AmberAlertStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Finished = 'finished',
}

export const AmberAlertStatusName = Object.freeze({
  [AmberAlertStatus.Pending]: 'In progress',
  [AmberAlertStatus.Cancelled]: 'Closed by user',
  [AmberAlertStatus.Finished]: 'Alarm raised',
});

export const AmberAlertActivityStatusName = Object.freeze({
  [AmberAlertActivityStatus.Started]: 'started',
  [AmberAlertActivityStatus.Cancelled]: 'cancelled',
  [AmberAlertActivityStatus.Finished]: 'finished',
});

export const RedAlertActivityStatusName = Object.freeze({
  [RedAlertActivityStatus.Started]: 'started',
  [RedAlertActivityStatus.Accepted]: 'accepted',
  [RedAlertActivityStatus.Completed]: 'completed',
  [RedAlertActivityStatus.Cancelled]: 'cancelled',
});

export enum EditUserSectionTab {
  General,
  Personal,
  Escalation,
  Contact,
  Vehicle,
}

export enum TaskState {
  Created = 'created',
  Deleted = 'deleted',
}

export enum SiteState {
  Created = 'created',
  Deleted = 'deleted',
}

export enum HeightUnit {
  Meter = 'm',
  FeetInch = 'ft.in',
}

export enum WeightUnit {
  Kilogram = 'kg',
  Pound = 'lb',
  Stone = 'st',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export const GenderName = Object.freeze({
  [Gender.Male]: 'Male',
  [Gender.Female]: 'Female',
  [Gender.Other]: 'Other',
});

export enum MonitoringMethod {
  ArcMonitored = 'arc_monitored',
  SelfMonitored = 'self_monitored',
}

export const ESCALATION_TYPE_LABELS = Object.freeze({
  [MonitoringMethod.SelfMonitored]: 'Self-Monitoring',
  [MonitoringMethod.ArcMonitored]: 'Vatix 24/7 Monitoring',
  not_monitored: 'Not Monitored',
});

export const PAGE_SIZE = 15;

export const LOCATION_API_REFRESH_INTERVAL = 10000;

export const LOCATION_HISTORY_PERIOD_LATEST = '0';

export const LOCATION_HISTORY_PERIOD = Object.freeze({
  [LOCATION_HISTORY_PERIOD_LATEST]: 'Most recent',
  '1': '1 hour',
  '3': '3 hours',
  '6': '6 hours',
  '12': '12 hours',
  '24': '24 hours',
});

export const { REACT_APP_ADMIN_UI_URL } = process.env;

export const DEFAULT_SEARCH_DEBOUNCE = 700;

export const DEFAULT_FULL_DATE_FORMAT = 'YYYY-MM-DD';

export const DOTS_FULL_DATE_FORMAT = 'DD.MM.YYYY';

export const DEFAULT_DATE_FORMAT = 'Do MMM YYYY';

export const DEFAULT_TIME_FORMAT = 'HH:mm';

export const DEFAULT_DATETIME_FORMAT_WITHOUT_YEAR = `Do MMM ${DEFAULT_TIME_FORMAT}`;

export const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATETIME_FORMAT_WITHOUT_YEAR} YYYY`;

export const FULL_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT}, ${DEFAULT_TIME_FORMAT}`;

export const FOLDED_MAP_HEIGHT = 300;

export const Products = {
  protector: process.env.REACT_APP_PROTECTOR_UI_URL as string,
  admin: process.env.REACT_APP_ADMIN_UI_URL as string,
  workflows: process.env.REACT_APP_WORKFLOWS_UI_URL as string,
};

export const USER_TAG_BASE = `${Products.protector}/dashboard/users/`;

export const MAX_FILE_SIZE = 50 * 1024 * 1024;

export const MAX_LOGO_SIZE = 2 * 1024 * 1024;

export enum EntitiesStatus {
  Open = 'open',
  Resolved = 'resolved',
  Archived = 'archived',
}

export const EntitiesStatusNames = Object.freeze({
  [EntitiesStatus.Open]: 'Open',
  [EntitiesStatus.Resolved]: 'Resolved',
  [EntitiesStatus.Archived]: 'Archived',
});
