import React from 'react';
import { observer } from 'mobx-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { formatDate, formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import dayjs from 'dayjs';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { Value } from 'containers/IncidentDetails/components/EditableTextInput/styles';
import { JSONSchemaType, LocationAnswerType, ProtectorType } from 'utils/api/types';

import IncidentStatusChip from 'components/Chips/IncidentStatusChip/IncidentStatusChip';

import UserRow from 'components/UserRow';

import { CustomFieldDisplayValueProps } from './types';
import { DescriptionField, LocationText } from '../IncidentInformation/styles';
import { EmptyValue } from './styles';
import UserField from '../UserField';

const CustomFieldDisplayValue: React.FunctionComponent<CustomFieldDisplayValueProps> = ({ schema, type, value }) => {
  const getEmptyValueForType = (protectorType: ProtectorType): string => {
    switch (protectorType) {
      case ProtectorType.User:
        return 'Not assigned';
      case ProtectorType.Location:
        return 'Not selected';
      default:
        return '-';
    }
  };

  if (value === null || value === undefined) {
    return <EmptyValue id="empty-value">{getEmptyValueForType(type)}</EmptyValue>;
  }
  switch (type) {
    case ProtectorType.Time:
      return <Value>{dayjs(value as number).format('HH:mm')}</Value>;
    case ProtectorType.DateTime:
      return <Value>{formatDateTime(value as number)}</Value>;
    case ProtectorType.Number:
      return <Value>{value as string}</Value>;
    case ProtectorType.ShortText:
      return <Value id="short-text-value">{value as string}</Value>;
    case ProtectorType.LongText:
      return <DescriptionField id="long-text-value">{value as string}</DescriptionField>;
    case ProtectorType.SingleChoice:
      return <Value id="single-choice-value">{value as string}</Value>;
    case ProtectorType.MultiChoice:
      return <Value id="multi-choice-value">{(value as string[]).join(', ')}</Value>;
    case ProtectorType.Date:
      return <Value id="date-value">{formatDate(value as number)}</Value>;
    case ProtectorType.User:
      if (typeof value === 'object') {
        return <UserRow user={(value as unknown) as UuidableName} />;
      }
      return <UserField userId={(value as string).replace('user:', '')} />;
    case ProtectorType.Status:
      return <IncidentStatusChip schema={schema as JSONSchemaType} label={value as string} />;
    case ProtectorType.Location: {
      const { address, lat, lng } = value as LocationAnswerType;
      if (lat === null && lng === null) {
        return <Value>-</Value>;
      }
      let displayValue = `Latitude: ${lat}, Longitude: ${lng}`;
      if (address) {
        displayValue = address;
      }
      return (
        <>
          <LocationOnIcon color="primary" />
          <LocationText id="location-value">{displayValue}</LocationText>
        </>
      );
    }
    case ProtectorType.Lookup:
      return <Value id="lookup-value">{(value as UuidableName).name}</Value>;
    default:
      return <Value id="not-supported-type">Field type not supported</Value>;
  }
};

export default observer(CustomFieldDisplayValue);
